import React, { FunctionComponent } from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { MetricColumnMap } from '@amzn/limestone-experiment-portal-types';
import { stringToNumber } from '../utils/number-utils';
import { Popover } from '@amzn/awsui-components-react-v3';

export interface DataTableDefinition {
    tableName: string;
    columnNames: string[];
    columnDescriptions: string[];
    rows: any[][];
}

export interface DataTableProps {
    tableDefinition: DataTableDefinition;
    expandableSection?: boolean;
    firstColumnHeader?: boolean;
}

const StyledTableCell = withStyles(() =>
    createStyles({
        head: {
            padding: '10px',
            fontSize: 12,
            textAlign: 'left',
            maxWidth: '100px',
            fontWeight: 'bold',
        },
        body: {
            padding: '10px',
            fontSize: 12,
            textAlign: 'left',
        },
    }),
)(TableCell);

const StyledBoldTableCell = withStyles(() =>
    createStyles({
        body: {
            padding: '10px',
            fontSize: 12,
            textAlign: 'left',
            fontWeight: 'bold',
            cursor: 'pointer'
        }
    })
)(TableCell);

export const DataTable: FunctionComponent<DataTableProps> = (props: DataTableProps) => {
    const rowColors = getRowColors(props.tableDefinition.columnNames, props.tableDefinition.rows);
    return (
        <Table data-testid={`raw-table-${props.tableDefinition.tableName}`} aria-label='simple table'>
            <TableHead>
                <TableRow>
                    {props.tableDefinition.columnNames.map((columnName, idx) => (
                        <StyledTableCell key={columnName}>
                            {props.tableDefinition.columnDescriptions[idx] ? <Popover
                                dismissButton={false}
                                position='top'
                                size='small'
                                content={props.tableDefinition.columnDescriptions[idx]}>
                                <p style={{ cursor: 'pointer', margin: 0, padding: 0 }}>{columnName}</p>
                            </Popover> : { columnName }}
                        </StyledTableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {props.tableDefinition.rows.length
                    ? props.tableDefinition.rows.map((row: any[], index) =>
                        <TableRow key={index} style={{ backgroundColor: rowColors[index] }}>
                            {props.firstColumnHeader ?
                                (<StyledBoldTableCell component='th' scope='row'>{row[0]}</StyledBoldTableCell>) :
                                (<StyledTableCell component='th' scope='row'>{row[0]}</StyledTableCell>)
                            }
                            {row.slice(1, row.length).map((cell, index) => <StyledTableCell
                                key={index}>{cell}</StyledTableCell>)}
                        </TableRow>
                    )
                    : <TableRow><StyledTableCell component='th' scope='row'>{'No data found'}</StyledTableCell></TableRow>
                }
            </TableBody>
        </Table>
    );
};

const getRowColors = (columnNames: string[], rows: string[][]): string[] => {
    let lowerBoundColumn: number, upperBoundColumn: number;
    if (columnNames.includes(MetricColumnMap.LOWER_BOUND_90) && columnNames.includes(MetricColumnMap.UPPER_BOUND_90)) {
        lowerBoundColumn = columnNames.indexOf(MetricColumnMap.LOWER_BOUND_90);
        upperBoundColumn = columnNames.indexOf(MetricColumnMap.UPPER_BOUND_90);
    }

    let pValueColumn: number, absoluteLiftColumn: number;
    if (columnNames.includes(MetricColumnMap.P_VALUE) && columnNames.includes(MetricColumnMap.ABSOLUTE_LIFT)) {
        pValueColumn = columnNames.indexOf(MetricColumnMap.P_VALUE);
        absoluteLiftColumn = columnNames.indexOf(MetricColumnMap.ABSOLUTE_LIFT);
    }

    return rows.map((row) => {
        let rowColor = 'white';
        if (lowerBoundColumn !== undefined && upperBoundColumn !== undefined) {
            rowColor = stringToNumber(row[lowerBoundColumn]) > 0 ? 'lightgreen' : stringToNumber(row[upperBoundColumn]) < 0 ? 'lightcoral' : 'lightgray';
        } else if (pValueColumn !== undefined && absoluteLiftColumn !== undefined) {
            rowColor = stringToNumber(row[pValueColumn]) >= .1 ? 'lightgray' : stringToNumber(row[absoluteLiftColumn]) > 0 ? 'lightgreen' : 'lightcoral';
        }
        return rowColor;
    });
};
