import { LimestoneExperiment, ActionType, UserAccessLevel } from '@amzn/limestone-experiment-portal-types';
import { Box, Header, SpaceBetween } from '@amzn/awsui-components-react-v3';
import { EXPERIMENT_TREATMENT_COMPLETE_STATUSES, ExperimentStatusType } from '../../enums/ExperimentStatus';
import { PermissionControlledButton } from '../../permissions/PermissionControlledButton';
import { asinActionPage, experimentDashboardPage, experimentDetailPage, transactionalMetricsPage } from '../index';
import { decryptCustomerId } from '@amzn/amazon-id';
import React from 'react';
import { Realm } from '@amzn/limestone-experiment-portal-types';
import { Page } from '../../interfaces/Page';
import { EXPERIMENT_DETAIL_PAGE_PERMISSIONS } from '../../constants/auth/permission-models';
import { CopyToClipboard } from '../../common/CopyToClipboard';

interface ExperimentNavigationBarProps {
    experiment: LimestoneExperiment;
    additionalButtons?: JSX.Element[];
    realm: Realm;
    userAccessLevels: Set<UserAccessLevel>;
    currentPage: Page;
}

export const ExperimentNavigationBar =
    ({ experiment, additionalButtons, realm, userAccessLevels, currentPage }:ExperimentNavigationBarProps) => {

        const buttonsDisabledState = getButtonsDisabledState(experiment);

        return (
            <Box margin={{ 'bottom':'m','top':'xxs' }}>
                <Header
                    variant="h1"
                    actions={<SpaceBetween size="s" direction="horizontal">
                        {currentPage !== experimentDetailPage && <PermissionControlledButton
                            userAccessLevels={userAccessLevels}
                            actionType={ActionType.READ}
                            pagePermissionsMap={EXPERIMENT_DETAIL_PAGE_PERMISSIONS}
                            testId={'asin-actions-button'}
                            buttonProps={{
                                href: `${experimentDetailPage.path}?experimentId=${experiment.experimentId}&experimentIntegerId=${experiment.experimentIntegerId}&realm=${realm}`
                            }}
                        >Experiment Details</PermissionControlledButton>}

                        {currentPage !== asinActionPage && <PermissionControlledButton
                            userAccessLevels={userAccessLevels}
                            actionType={ActionType.ADMIN_ACTION}
                            pagePermissionsMap={EXPERIMENT_DETAIL_PAGE_PERMISSIONS}
                            testId={'asin-actions-button'}
                            hideIfNotAuthorized={true}
                            buttonProps={{
                                disabled: buttonsDisabledState.asinActionsButtonDisabled,
                                href: `${asinActionPage.path}?experimentId=${experiment.experimentId}&experimentIntegerId=${experiment.experimentIntegerId}&realm=${realm}`
                            }}
                        >ASIN Actions</PermissionControlledButton>}

                        {currentPage !== transactionalMetricsPage && <PermissionControlledButton
                            userAccessLevels={userAccessLevels}
                            actionType={ActionType.READ}
                            pagePermissionsMap={EXPERIMENT_DETAIL_PAGE_PERMISSIONS}
                            testId={'transactional-metrics-button'}
                            buttonProps={{
                                disabled: buttonsDisabledState.viewMetricsButtonDisabled,
                                href: `${transactionalMetricsPage.path}?experimentId=${experiment.experimentId}&experimentIntegerId=${experiment.experimentIntegerId}&realm=${realm}`
                            }}
                        >View Metrics</PermissionControlledButton>}

                        {currentPage !== experimentDashboardPage && <PermissionControlledButton
                            userAccessLevels={userAccessLevels}
                            actionType={ActionType.ELEVATED_READ}
                            pagePermissionsMap={EXPERIMENT_DETAIL_PAGE_PERMISSIONS}
                            testId={'quicksight-dashboard-button'}
                            buttonProps={{
                                disabled: buttonsDisabledState.experimentDashboardButtonDisabled,
                                href: `${experimentDashboardPage.path}?marketplaceId=${decryptCustomerId(experiment.metadata.marketplace.payloadValue)}&experimentId=${experiment.experimentId}&experimentIntegerId=${experiment.experimentIntegerId}&realm=${realm}`
                            }}
                        >QuickSight Dashboard</PermissionControlledButton>}
                        {additionalButtons}
                    </SpaceBetween>}
                >
                    <Header variant="h1"><SpaceBetween size={'s'} direction={'horizontal'}>
                        {`${experiment.metadata.title.displayValue} (ID: ${experiment.experimentIntegerId})`}
                        <CopyToClipboard label={'ExperimentId'} textToCopy={experiment.experimentId}/>
                    </SpaceBetween></Header>
                    <Box variant={'span'} fontWeight={'bold'}
                        fontSize={'heading-m'}>{'Current Status: '}{experiment.currentStatus.currentStatus.displayValue}</Box>
                </Header>
            </Box>);
    };


const getButtonsDisabledState = (experiment: LimestoneExperiment) => {
    const buttonsDisabledState = {
        asinActionsButtonDisabled: true,
        viewMetricsButtonDisabled: true,
        experimentDashboardButtonDisabled: true,
    };

    const experimentStatus: ExperimentStatusType = experiment.currentStatus.currentStatus.payloadValue;

    if (experimentStatus === ExperimentStatusType.RUNNING) {
        buttonsDisabledState.asinActionsButtonDisabled = false;
    }

    if (experimentStatus === ExperimentStatusType.RUNNING || EXPERIMENT_TREATMENT_COMPLETE_STATUSES.includes(experimentStatus)) {
        buttonsDisabledState.viewMetricsButtonDisabled = false;
        buttonsDisabledState.experimentDashboardButtonDisabled = false;
    }

    return buttonsDisabledState;
};
