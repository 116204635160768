import { MetricsTable, TableDimension, TransactionalMetricsRow } from '@amzn/limestone-experiment-portal-types';
import { DataTableDefinition } from '../../../common/DataTable';
import { MetricColumnMapV2 } from '@amzn/limestone-experiment-portal-types';
import { DataTableGroupDefinition } from '../../../common/DataTableGroup';

export const convertMetricsTableToDataTableGroup = (
    metricsTable: MetricsTable,
    overrideGroupName?: string): DataTableGroupDefinition[] => {

    const dimensionColumnMap = new Map(Object.entries(metricsTable.schema.dimensionColumnIndex));
    const metricColumnMap = new Map(Object.entries(metricsTable.schema.metricColumnIndex));

    const productGroupIdx = dimensionColumnMap.get(TableDimension.AGGREGATED_DIMENSION)!;
    const channelIdx = dimensionColumnMap.get(TableDimension.CHANNEL);
    const metricNameIdx = dimensionColumnMap.get(TableDimension.METRIC_DIMENSION)!;
    const productGroupToChannels = new Map<string, Set<string>>(
        metricsTable.rows.map((row) => [row.dimensionValues[productGroupIdx], new Set()]));

    if (channelIdx !== undefined) {
        metricsTable.rows.forEach((row) => {
            productGroupToChannels.get(row.dimensionValues[productGroupIdx])!.add(row.dimensionValues[channelIdx]);
        });
    }

    const colNames = Object.keys(metricsTable.schema.metricColumnIndex);
    const colDescriptions = new Array(colNames.length);
    const cols: string[] = new Array(colNames.length);
    for (let colName of colNames) {
        cols[metricColumnMap.get(colName)] = (MetricColumnMapV2 as any)[colName].displayName;
        colDescriptions[metricColumnMap.get(colName)] = (MetricColumnMapV2 as any)[colName].description;
    }
    const columnNames: string[] = ['Metric', ...cols];
    const columnDescriptions: string[] = ['Metric Name', ...colDescriptions]; 

    return Array.from(productGroupToChannels.keys()).map((productGroup) => {
        const dataTableDefinitions: DataTableDefinition[] = [];

        if (channelIdx !== undefined) {
            productGroupToChannels.get(productGroup)!.forEach((channel) => {
                dataTableDefinitions.push({
                    columnNames: columnNames,
                    columnDescriptions,
                    tableName: channel,
                    rows: metricsTable.rows
                        .filter((row: TransactionalMetricsRow) => row.dimensionValues[productGroupIdx] === productGroup)
                        .filter((row: TransactionalMetricsRow) => row.dimensionValues[channelIdx] === channel)
                        .map((row: TransactionalMetricsRow) => [row.dimensionValues[metricNameIdx], ...row.metricValues])
                });
            });
        } else {
            dataTableDefinitions.push({
                columnNames: columnNames,
                columnDescriptions,
                tableName: productGroup,
                rows: metricsTable.rows
                    .filter((row: TransactionalMetricsRow) => row.dimensionValues[productGroupIdx] === productGroup)
                    .map((row: TransactionalMetricsRow) => [row.dimensionValues[metricNameIdx], ...row.metricValues])
            });
        }

        return {
            groupName: overrideGroupName ? overrideGroupName : productGroup,
            dataTableDefinitions: dataTableDefinitions
        };
    });
};
