import React, { FunctionComponent } from 'react';
import { QuickStartSection } from './QuickStartSection';
import { PageProps, Portal, adminQuickLinkCardItems, basicQuickLinkCardItems } from '@amzn/limestone-experiment-portal-types';
import { HomePageHeader } from './HomePageHeader';
import { Flashbar } from '@amzn/awsui-components-react-v3';
import { REALM_TO_MAKRETPLACE_MAP } from '../../constants/experiment/marketplace-list';
import { QuickLinksSection } from './QuickLinksSection';

const HomePage: FunctionComponent<PageProps> = (props) => {
    const [items, setItems] = React.useState([
        {
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setItems([]),
            content: (
                <>
                    You are currently in the {props.realm.toUpperCase()} realm which includes {REALM_TO_MAKRETPLACE_MAP[props.realm]} marketplace(s),
                    use the top navigation bar to switch to a different realm
                </>
            ),
            id: 'message_1'
        }
    ]);

    const cardItems = props.portal === Portal.ADMIN ? adminQuickLinkCardItems.call([],props.realm) : basicQuickLinkCardItems.call([],props.realm);

    return (
        <>
            <HomePageHeader {...props}/>
            <QuickStartSection />
            <hr/>
            <Flashbar items={items}/>
            <QuickLinksSection cardItems={cardItems} />
        </>
    );
};

export default HomePage;
